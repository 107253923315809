export default [
  {
    title: "Студии",
    subtitle: "от 26 м²",
    properties: {
      preview: require("@/assets/i/second-plans/st.jpg"),
    },
  },
  {
    title: "Евро-2",
    subtitle: "от 40,5 м²",
    properties: {
      preview: require("@/assets/i/second-plans/e2.jpg"),
    },
  },
  {
    title: "Евро-3",
    subtitle: "от 60,7 м²",
    properties: {
      preview: require("@/assets/i/second-plans/e3.jpg"),
    },
  },
  {
    title: "Евро-4",
    subtitle: "от 90,7 м²",
    properties: {
      preview: require("@/assets/i/second-plans/e4.jpg"),
    },
  },
];
