<template>
  <div
    class="item text-white flip-flat"
    :class="{ active: isActive, 'cursor-flip': flip }"
    @click="flip ? (isActive = !isActive) : undefined"
  >
    <div class="item__inner">
      <div class="item__front">
        <div v-if="item.building && item.floor?.number" class="flip-flat__top">
          {{ item.building }} корпус {{ item.floor.number }} этаж
        </div>
        <div v-if="item.properties.preview" class="flip-flat__image">
          <img
            loading="lazy"
            width="420"
            class="img-to-animate"
            :src="item.properties.preview"
            :alt="item.title"
          />
        </div>
        <div class="flip-flat__bottom">
          <div class="h3 flip-flat__title">
            <span>{{ item.title }}</span><br>
            <template v-if="item.subtitle">{{ item.subtitle }}</template>
            <template v-if="item.layout?.area">{{ item.layout.area }} м²</template>
          </div>
          <div class="flip-flat__more"><slot name="more">Подробнее</slot></div>
        </div>
      </div>
      <div v-if="flip" class="item__back">
        <div class="flip-flat__bottom">
          <div class="h3 flip-flat__title">
            <span>{{ item.title }}</span><br>
            {{ item.layout.area }} м²
          </div>
          <div class="flip-flat__top">
            {{ item.building }} корпус {{ item.floor.number }} этаж
          </div>
          <div class="flip-flat__props">
            <div class="flip-flat__prop" v-for="(prop, i) in item.properties.props" :key="i">
              {{ prop }}
            </div>
          </div>
          <router-link class="flip-flat__more" :to="{
            name: 'UnicLotFlat',
            params: {
              id: item.id
            }
          }">Подробнее</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
    flip: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isActive: false,
    };
  },
};
</script>

<style scoped>
.flip-flat__bottom {
  margin-top: auto;
  padding: 0 32px 32px 32px;
  align-items: baseline;
}
.flip-flat__bottom .flip-flat__more {
  width: fit-content;
  color: #3F4440;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  padding: 0;
  background-color: transparent;
  border: 0;
  border-bottom: 2px #509C3B solid;
  margin-top: 16px;
}
.flip-flat__bottom .flip-flat__more :deep(a) {
  text-decoration: none;
}
.flip-flat__top {
  position: absolute;
  top: 16px;
  right: 16px;
  color: #205640;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}
.flip-flat__title {
  color: #3F4440;
  font-size: 42px;
  font-style: normal;
  font-weight: 400;
  line-height: 56px;
  letter-spacing: -1.26px;
  margin-bottom: auto;
}
.flip-flat__title span {
  color: #509C3B;
  font-size: 48px;
  font-style: normal;
  font-weight: 400;
  line-height: 56px; /* 116.667% */
  letter-spacing: -1.44px;
}
.flip-flat__prop {
  color: #FFF;
  font-family: Mabry Pro;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: 26px; /* 144.444% */
  margin-bottom: 6px;
}
.item {
  position: relative;
  flex: 0 0 420px;
  height: 560px;
  margin-right: 24px;
  background-color: #fff;
  -webkit-perspective: 1000px;
  perspective: 1000px;
  border: 1px solid #E5E5E5;
}
.item__back .flip-flat__top {
  color: #FFF;
}
.item__back .flip-flat__bottom {
  padding: 0;
  margin-top: 0;
  display: flex;
  flex-direction: column;
  height: 100%;
}
.item__back .flip-flat__bottom .flip-flat__more {
  color: #FFF;
}
.item__back .flip-flat__top {
  position: static;
  margin-bottom: auto;
}
.item__back .flip-flat__title {
  color: #FFF;
  margin-bottom: 14px;
}
.item__back .flip-flat__title span {
  color: #FFF;
}

.item:last-child {
  margin-right: 0;
}

.item:nth-child(even) {
  margin-top: 80px;
}

.item__inner {
  position: relative;
  width: 100%;
  height: 100%;
  transition: transform 0.8s;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.item__front {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  display: flex;
  flex-direction: column;
}

.item__back {
  position: absolute;
  width: 100%;
  height: 100%;
  transform: rotateY(180deg);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  background-color: #205640;
  padding: 32px;
}

.item.active .item__inner {
  transform: rotateY(-180deg);
}

.item__image {
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  width: 100%;
  height: 100%;
  background-color: #205640;
}

.item__image img {
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.item__back::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    180deg,
    rgb(63 68 64 / 0) 0%,
    rgb(63 68 64 / 0.4) 100%
  );
}

.item__content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 30px;
}

.item__title {
  margin: 0 0 15px;
  letter-spacing: -0.03em;
}

.item__time {
  font-size: 16px;
}

.item__back::after {

  background-image: url(~@i/text-mask.png);
  background-position: 50% 50%;
  background-size: 420px 560px;
  opacity: 0.2;
}

.item__back .item__time {
  opacity: 0.6;
}
@media screen and (max-width: 767px) {
  .flip-flat__top {
    font-size: 14px;
  }
  .flip-flat__title {
    font-size: 32px;
    line-height: normal;
    margin-bottom: 8px;
  }
  .flip-flat__title span {
    font-size: 36px;
    line-height: normal;
  }
  .flip-flat__bottom {
    padding: 0 24px 24px 24px;
  }
  .flip-flat__bottom .flip-flat__more {
    font-size: 16px;
  }
  .item {
    height: 420px;
  }
  .item__back {
    padding: 24px;
  }
  .flip-flat__prop {
    font-size: 14px;
    margin-bottom: 0;
  }
  .flip-flat__bottom .flip-flat__more {
    margin-top: 8px;
  }
}
</style>
